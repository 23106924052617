<template>
  <div>
    <el-dialog
      title="审核通过"
      :visible.sync="show"
      width="40%"
      @close="$emit('update:dialogVisible', false)"
    >
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item label="企业号等级">
          <el-radio-group v-model="form.val" class="height">
            <p>
              <el-radio :label="0">无等级</el-radio><br />
              <span style="color: #fda400;font-size:12px;"
                >无等级会员一般用于商务挑选企业数据。不用于用户提交的审核。</span
              >
            </p>
            <p
              v-for="item in $store.state.config &&
                $store.state.config.enterpriseGrade"
            >
              <el-radio :label="item.id">{{ item.name }}</el-radio>
            </p>
            <p style="color: #fda400; font-size:12px">
              审核前确认客户已经完成支付或转账。请商务和客服自行跟客户确认会员类型。避免误操作！！！！！
            </p>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="beforesub">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        val: ''
      },
      show: this.dialogVisible
    }
  },

  watch: {
    dialogVisible() {
      this.show = this.dialogVisible
      // console.log(this.row)
      if (this.row.egId == 1) {
        this.form.val = 1
      } else {
        this.form.val = ''
      }
    }
  },
  created() {
    // if (this.row.egId == 0) {
    //   console.log(1)
    // }
  },
  methods: {
    clear() {
      this.$emit('update:dialogVisible', false)
    },
    beforesub() {
      this.$confirm('选择付费等级前请确认客户是否支付，或者此客户不需要支付！！！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
            this.submit()
        })
        .catch(() => {
        })
    },
    submit() {
      this.$http
        .post('/admin/Enterprise/check', {
          id: this.row.id,
          status: 3,
          remark: '',
          egId: this.form.val
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.$emit('update:dialogVisible', false)
            this.$emit('getlist')
          } else {
            this.$message.error(res.message)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
// .el-dialog__header {
//   text-align: center;
// }
.height {
  margin-left: 20px;
}
.height > p {
  margin-bottom: 20px;
}
.height > p label {
  margin-bottom: 10px;
}
</style>
