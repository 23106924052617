<template>
  <div>
    <el-dialog
      title="审核不通过原因"
      :visible.sync="show"
      width="30%"
      style="text-align: center;"
      @close="$emit('update:dialogVisible', false)"
    >
      <h1>企业基本信息不通过</h1>
      <el-input
        type="textarea"
        v-if="form"
        :rows="7"
        placeholder=""
        v-model="form.remark"
      ></el-input>
      <h1>企业认证信息不通过</h1>
      <el-input
        type="textarea"
        v-if="form"
        :rows="7"
        placeholder=""
        v-model="form.extend.remark"
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: {
        remark: '',
        extendRemark: ''
      }
    }
  },
  data() {
    return {
      show: this.dialogVisible
    }
  },
  watch: {
    dialogVisible() {
      this.show = this.dialogVisible
    }
  },
  methods: {
    clear() {
      this.$emit('update:dialogVisible', false)
    },
    submit() {
      this.$http
        .post('/admin/Enterprise/check', {
          ...this.form,
          extendRemark: this.form.extend.remark,
          status: 4
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.$emit('update:dialogVisible', false)
            this.$emit('getlist')
          } else {
            this.$message.error(res.message)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
h1 {
  font-size: 16px;
  // font-weight: bold;
  margin: 15px;
}
.el-dialog__header {
  text-align: center;
}
</style>
